import React from 'react';
import { usd } from '../../utils/dom-utils';

export const PricePartRow = ({ part }) => {
    return (
        <tr className="price-part-row">
            <td>{part.desc}</td>
            <td>{part.number}</td>
            <td>{part.qty}</td>
            <td>{part.price}</td>
            <td>{usd(part.qty * part.price)}</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
    );
};
