import React from 'react';
import { usd } from '../../utils/dom-utils';
import { Link } from 'react-router-dom';
import { has } from 'lodash';

export const OrderedPartRow = ({ part, onOrderLinkClick }) => {
    const getOrderLineStatus = order => {
        let lineStatus = has(order, 'orderLine.statusDescription') ? order.orderLine.statusDescription : '';

        if (order.status === '3') {
            lineStatus = order.statusDescription;
        }

        return lineStatus;
    };

    const rowColor = part.submitted ? 'table-success' : 'table-primary';

    return (
        <tr className={`ordered-part-row ${rowColor}`}>
            <td>{part.desc}</td>
            <td>{part.number}</td>
            <td>{part.qty}</td>
            <td>{part.price}</td>
            <td>{usd(part.qty * part.price)}</td>
            <td>
                <Link onClick={onOrderLinkClick(part.meta.orderId)}>{part.meta.orderId}</Link>
            </td>
            <td>{part.meta.comment}</td>
            <td>{getOrderLineStatus(part.meta)}</td>
        </tr>
    );
};
