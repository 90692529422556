import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const NavMenu = props => {
    const [mainMenuItems, setMainMenuItems] = useState([]);
    const [altMenuItems, setAltMenuItems] = useState([]);
    const navigate = useNavigate();
    const isAuthenticated = props.isAuthenticated || false;
    const logoSize = 30;

    useEffect(() => {
        setMainMenuItems([
            { name: 'Home', link: '/', iconClass: 'bi-house' },
            { name: 'Customers', link: '/customers', iconClass: 'bi-people' },
            { name: 'Work Orders', link: '/work-orders', iconClass: 'bi-card-heading' },
        ]);

        setAltMenuItems([
            { name: 'Settings', link: '/settings', iconClass: 'bi-gear' },
            { name: 'Logout', link: '/logout', iconClass: 'bi-box-arrow-right' },
        ]);
    }, []);

    if (mainMenuItems.length === 0) return null;

    const handleNavigation = link => event => {
        event.preventDefault();
        event.stopPropagation();

        navigate(link);
    };

    const renderMenuItems = (items = []) =>
        items.map(item => (
            <li className="nav-item" key={item.name}>
                <a href={item.link} onClick={handleNavigation(item.link)} className="nav-link" aria-current="page">
                    {item.iconClass && <i className={`bi-icon ${item.iconClass}`}></i>}
                    {item.name}
                </a>
            </li>
        ));

    return (
        <nav className="navbar navbar-expand-lg bg-primary" data-bs-theme="dark" id={'main-nav'}>
            <div className="container-fluid">
                <a className="navbar-brand" href="/" onClick={handleNavigation('/')}>
                    <img src={'/logo_192.png'} alt="logo" height={logoSize} width={logoSize} />
                    WORLDPAC SMS Demo
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarColor02"
                    aria-controls="navbarColor02"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                {isAuthenticated && (
                    <div className="collapse navbar-collapse justify-content-between">
                        <ul className="nav mb-auto">{renderMenuItems(mainMenuItems)}</ul>
                        <ul className="nav d-flex">{renderMenuItems(altMenuItems)}</ul>
                    </div>
                )}
            </div>
        </nav>
    );
};

export default NavMenu;
